import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { isEqual } from "lodash-es";
import {
  requestTextblockCategories,
  postTextblockCategory as postData,
  deleteTextblockCategory as deleteData,
} from "actions/textblockCategories";
import Input from "../form/Input";
import InputInt from "../form/InputInt";
import ManagementSubmit from "./ManagementSubmit";
import IsDirtyHelper from "./IsDirtyHelper";

const TextblockCategoryForm = (props) => {
  const className = [
    "managment-detail",
    "management-detail--" + props.type,
  ].join(" ");

  const initialData = useMemo(
    () => ({
      id: null,
      name: "",
      export_order: 0,
    }),
    []
  );

  const [data, setData] = useState();
  const [original, setOriginal] = useState();
  const [isDirty, setIsDirty] = useState(false);

  const {
    params: { id },
    dispatch,
    textblockCategory,
  } = props;

  useEffect(() => {
    if (!data && id) {
      dispatch(requestTextblockCategories(id));
    }
  }, [id, data, dispatch]);

  useEffect(() => {
    if (!id) {
      setData(initialData);
    }
  }, [id, initialData]);

  useEffect(() => {
    const o = textblockCategory
      ? {
          id: textblockCategory.id,
          name: textblockCategory.name,
          export_order: textblockCategory.export_order,
        }
      : initialData;
    setOriginal(o);
    setData({ ...o });
  }, [textblockCategory, initialData]);

  const setDataProp = (key, value) => {
    const d = {
      ...data,
      [key]: value,
    };
    setData(d);
    setIsDirty(!isEqual(original, d));
  };

  if (!data) {
    return null;
  }

  const _postData = (data) => (dispatch) => {
    return dispatch(postData(data)).then(() => {
      setIsDirty(false);
      setOriginal(data);
    });
    // .catch(() => {
    //   setIsDirty(true)
    // })
  };

  const onClickBack = () => {
    dispatch(routerActions.push("/management/" + props.type));
  };

  const onClickReset = () => {
    setData(initialData);
  };

  return (
    <div className={className + " flex flex-col h-full overflow-hidden"}>
      <div className="md:flex md:items-center md:justify-between px-8 py-5 bg-white shadow-md z-10 border-b border-gray-100">
        <button
          type="button"
          className=" inline-flex p-2 mr-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={onClickBack}
        >
          <i className="fal fa-long-arrow-left" />
        </button>
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
            {id ? data.name : "Beurteilungskategorie hinzufügen"}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <ManagementSubmit
            create={data.id === null}
            isDirty={isDirty}
            data={data}
            post={_postData}
            delete={deleteData}
            onClickBack={onClickBack}
            onClickReset={onClickReset}
          />
        </div>
      </div>

      <div className="management-form flex-1 h-full overflow-auto bg-gray-100 p-12">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-2 gap-12 mb-8">
            {/* <SelectGrade value={data.grade_id} onChange={(value) => setDataProp('grade_id', value)} /> */}
            <Input
              label="Titel"
              placeholder="Bsp: Qualität der Mitarbeit"
              value={data.name}
              onChange={(value) => setDataProp("name", value)}
            />
            <InputInt
              label="Reihenfolge"
              value={data.export_order}
              onChange={(value) => setDataProp("export_order", value)}
            />
          </div>

          {isDirty && (
            <IsDirtyHelper
              route={props.route}
              original={original}
              data={data}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  const textblockCategory = state.entities.textblock_categories
    ? state.entities.textblock_categories[ownProps.params.id]
    : null;
  return {
    type: "textblock_categories",
    textblockCategory,
    textblockCategories: state.entities.textblock_categories,
  };
})(TextblockCategoryForm);
