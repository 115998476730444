import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

const Multiselect = (props) => {
  const { items, onChange, value, request, dispatch, type, groupBy } = props

  const [options, setOptions] = useState()
  const [optionsLength, setOptionsLength] = useState(0)
  const [values, setValues] = useState()
  const [valuesLength, setValuesLength] = useState(0)
  const [query, setQuery] = useState('')

  const add = (ids) => {
    const newValues = [...options].filter(p => ids.includes(p.id))
    setOptions([...options].filter(p => !ids.includes(p.id)))
    const _values = values ? [...values, ...newValues] : [...newValues]
    setValues(_values)
    onChange(_values.map(v => v.id))
  }

  const remove = (ids) => {
    const newOptions = [...values].filter(p => ids.includes(p.id))
    setOptions([...options, ...newOptions])
    const _values = values ? [...values].filter(p => !ids.includes(p.id)) : []
    setValues(_values)
    onChange(_values.map(v => v.id))
  }

  const addGroup = (groupId) => {
    const ids = options.filter(o => {
      return o.groupBy && o.groupBy.id === groupId
    })
      .map(o => o.id)
    add(ids)
  }

  const removeGroup = (groupId) => {
    const ids = values.filter(v => {
      return v.groupBy && v.groupBy.id === groupId
    })
      .map(v => v.id)
    remove(ids)
  }

  useEffect(() => {
    if (!items || items.length === 0) {
      dispatch(request())
    }
  }, [])

  useEffect(() => {
    if ((!!items && items.length !== optionsLength) || value.length !== valuesLength) { //(options === undefined || options.length === 0)) {
      const o =
        Object.keys(items).reduce((acc, index) => {
          const item = items[index]
          if (items && !value.includes(item.id)) {
            if (item) {
              acc.push({
                id: item.id,
                name: item.name,
                label: item.label,
                groupBy: groupBy ? { ...get(item, [groupBy]) } : null,
              })
            }
          }
          return acc
        }, [])
      setOptions(o)
      setOptionsLength(o.length)
    }
    if (!!items && !!value && value.length !== valuesLength) { // values === undefined) {
      const v = value.reduce((acc, id) => {
        if (items) {
          const item = items.find(i => i.id === parseInt(id))
          if (item) {
            acc.push({
              id: item.id,
              name: item.name,
              label: item.label,
              groupBy: groupBy ? { ...get(item, [groupBy]) } : null,
            })
          }
        }
        return acc
      }, [])
      setValues(v)
      setValuesLength(v.length)
    }
  }, [items, value, optionsLength, valuesLength, groupBy])

  const className = "multiselect multiselect--" + type

  return (
    <div className={className}>
      <label className="py-2 block text-sm font-medium text-gray-700" >{props.label}</label>
      <div className="bg-transparent grid grid-cols-2 gap-2 h-96">
        <div className="bg-white h-full shadow-sm text-sm flex flex-col overflow-hidden border border-gray-300 rounded-md">
          <div className="px-4 py-2 border-b border-gray-300">
            <div className="block text-gray-600 text-center uppercase text-xs tracking-wider mb-2 font-medium">{optionsLength} Verfügbar</div>
            <input className="flex-initial block p-2 mb-2 w-full border border-gray-300 rounded-full" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Suche..." />
          </div>
          <div className="flex-1 overflow-y-scroll">
            {options && options
              .sort((a, b) => {
                if (groupBy && a.groupBy && b.groupBy && a.groupBy.name !== b.groupBy.name) {
                  return a.groupBy.name > b.groupBy.name ? 1 : -1
                }
                return a.name > b.name ? 1 : -1
              })
              .reduce((acc, item) => {
                if (groupBy && (acc.length === 0 || (acc[acc.length - 1].groupBy && item.groupBy && acc[acc.length - 1].groupBy.id !== item.groupBy.id))) {
                  acc.push({
                    ...item.groupBy,
                    label: item.groupBy.name,
                    isGroup: true,
                  })
                }
                acc.push(item)
                return acc
              }, [])
              .filter(item => {
                const regExp = new RegExp(query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'ig')
                return item.label?.match(regExp)
              })
              .map(item => {
                return item.isGroup
                  ? <button
                    key={`group-${item.id}`}
                    className="flex justify-between w-full px-4 py-3 font-medium border-b cursor-pointer bg-gray-100 hover:no-underline hover:bg-blue-100 focus:outline-none"
                    onClick={() => addGroup(item.id)}
                    onKeyDown={(e) => { console.log(e); e.preventDefault()}}
                    onKeyUp={(e) => { console.log(e); e.preventDefault()}}
                    onKeyPress={(e) => { console.log(e); e.preventDefault()}}
                  >
                    <span>{item.label}</span>
                    <span className="text-blue-700">Alle hinzufügen <i className="far fa-long-arrow-right" /></span>
                  </button>
                  : <button
                    key={item.id}
                    className="flex justify-between w-full px-4 py-3 pl-6 border-b cursor-pointer hover:no-underline hover:bg-blue-100 focus:outline-none"
                    onClick={() => add([item.id])}
                    onKeyDown={(e) => { console.log(e); e.preventDefault()}}
                    onKeyUp={(e) => { console.log(e); e.preventDefault()}}
                    onKeyPress={(e) => { console.log(e); e.preventDefault()}}
                  >
                    <span>{item.label}</span>
                    <span className=""><i className="fal fa-long-arrow-right" /></span>
                  </button>
              })}
          </div>
        </div>
        <div className="bg-white h-full shadow-sm text-sm flex flex-col overflow-hidden border border-gray-300 rounded-md">
          <div className="px-4 py-2 border-b border-gray-300">
            <div className="block text-gray-600 text-center uppercase text-xs tracking-wider mb-2 font-medium">{valuesLength} Ausgewählt</div>
          </div>
          <div className="flex-1 overflow-y-scroll">
          {values && values
            .sort((a, b) => {
              if (groupBy && a.groupBy && b.groupBy && a.groupBy.name !== b.groupBy.name) {
                return a.groupBy.name > b.groupBy.name ? 1 : -1
              }
              return a.name > b.name ? 1 : -1
            })
            .reduce((acc, item) => {
              if (groupBy && (acc.length === 0 || (acc[acc.length - 1].groupBy && item.groupBy && acc[acc.length - 1].groupBy.id !== item.groupBy.id))) {
                acc.push({
                  ...item.groupBy,
                  label: item.groupBy.name,
                  isGroup: true,
                })
              }
              acc.push(item)
              return acc
            }, [])
            .map(item => {
              return item.isGroup
                ? <button
                    key={`group-${item.id}`}
                    className="flex justify-between w-full px-4 py-3 mt-2 font-medium border-b border-t cursor-pointer bg-gray-100 hover:no-underline hover:bg-blue-100 focus:outline-none"
                    onClick={() => removeGroup(item.id)}
                    onKeyDown={(e) => { console.log(e); e.preventDefault()}}
                    onKeyUp={(e) => { console.log(e); e.preventDefault()}}
                    onKeyPress={(e) => { console.log(e); e.preventDefault()}}
                  >
                    <span>{item.label}</span>
                    <span className="text-blue-700">Alle entfernen <i className="far fa-times" /></span>
                  </button>
                :
                <button
                  key={item.id}
                  /* inline-block mr-1 mb-1 bg-gray-200 text-black p-1 px-2 rounded */
                  className="inline-block ml-2 mt-2 bg-gray-100 text-gray-700 hover:text-blue-600 py-2 px-4 text-sm rounded-md  border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 font-medium"
                  onClick={() => remove([item.id])}
                  onKeyDown={(e) => { console.log(e); e.preventDefault()}}
                  onKeyUp={(e) => { console.log(e); e.preventDefault()}}
                  onKeyPress={(e) => { console.log(e); e.preventDefault()}}
                >
                  {item.label}
                  <span><i className="far fa-trash ml-2" /></span>
                </button>
            })}
            </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state, ownProps) => {
  const items = state.entities[ownProps.type]
    ? Object.keys(state.entities[ownProps.type]).map(key => {
      const item = state.entities[ownProps.type][key]
      item.label = item.name
      if (!item.name || ownProps.renderName) {
        item.label = ownProps.renderName ? ownProps.renderName(item) : 'no_name_set'
      }
      return item
    })
    : []
  return {
    items,
    value: ownProps.value || [],
  }
})(Multiselect)