import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
// import { routerActions } from 'react-router-redux'
import { requestCourses } from 'actions/courses'
// import Sidebar from 'components/Sidebar'
import Layout from 'containers/Layout'
import AuthSelector from 'selectors/auth'

const Ratings = (props) => {

  const [loading, setLoading] = useState(false)
  const { courses, user, children, dispatch } = props

  useEffect(() => {
    if (!courses) {
      setLoading(true)
      dispatch(requestCourses())
        .then(() => setLoading(false))
    }
  }, [courses, dispatch])

  let courseCategories = []
  if (courses) {
    courseCategories = courses.filter(c => c.is_parent)
      .reduce((acc, c) => {
        if (!acc.find(a => a.id === c.course_category.id)) {
          acc.push(c.course_category)
        }
        return acc
      }, courseCategories)
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
  }
  // let types = null
  // if (props.courses) {
  //   types = props.courses.reduce((acc, course) => {
  //     const exportSection = course.course_category && course.course_category.export_section
  //     if (exportSection && !acc.find(type => type.id === exportSection.id)) {
  //       acc.push({
  //         ...exportSection,
  //         courses: props.courses.filter(c => c.course_category.export_section.id === exportSection.id).sort((t1, t2) => {
  //           return t1.name > t2.name ? 1 : -1
  //         }),
  //       })
  //     }
  //     return acc
  //   }, [])
  // }

  let navigation = user && user.is_staff
    ? [{
      headline: "",
      items: [
        {
          name: 'Verwaltungsbereich',
          icon: 'long-arrow-right',
          href: '#/management/',
        },
      ]
    }]
    : []

  if (props.courses) {
    navigation = courseCategories.reduce((acc, courseCategory) => {
      const items = props.courses
        .filter(c => c.course_category.id === courseCategory.id)
        .map(course => {
          const href = "/ratings/course/" + course.id
          const ratings = [
            {
              name: course.rating_type ? course.rating_type.name : '-',
              icon: null,
              href: "#" + href,
              current: props.router.getCurrentLocation().pathname.indexOf(href) === 0,
            },
          ]
          if (course.child_courses.length) {
            course.child_courses.reduce((acc, c) => {
              const href = "/ratings/course/" + c.id
              acc.push({
                name: c.rating_type ? c.rating_type.name : '-',
                icon: null,
                href: "#" + href,
                current: props.router.getCurrentLocation().pathname.indexOf(href) === 0,
              })
              return acc
            }, ratings)
          }

        return ratings.length > 1
          ? {
            name: course.name,
            ratings,
          }
          : {
            ...ratings[0],
            name: course.name,
          }
      })
      if (items.length > 0) {
        acc.push({
          headline: courseCategory.name,
          items,
        })
      }
      return acc
    }, navigation)
  }

  return <Layout navigation={navigation} loading={loading}>
    {children}
    {children === null &&
      <div className="w-full h-full flex flex-col justify-center items-center">
        <i className="fal fa-mug-hot fa-4x text-gray-300 p-4" />
        <span className="text-gray-500">Bitte treffen Sie eine Auswahl in der Navigation links!</span>
      </div>
    }
  </Layout>
}

export default connect((state, ownProps) => {
  const courses = state.entities && state.entities.courses
    ? Object.keys(state.entities.courses).map(k => state.entities.courses[k])
    : null

  return ({
    user: AuthSelector.getActiveUser(state),
    courses,
    query: state.management.query || '',
  })
})(Ratings)